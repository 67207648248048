<template>

  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-card
        v-if="!showOverlay"
        no-body
    >

      <div class="m-2">
        <b-row>

          <!-- Per Page -->
          <b-col
              v-if="url"
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Pagina: {{page}} / {{pageCount}}</label>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="page = page - 1"
                class="ml-1"
                :disabled="pageCount === '1' || page === 1"
            >
              <span>Vorige</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="page = page + 1"
                class="ml-1"
                :disabled="pageCount === page || pageCount === 1"
            >
              <span>Volgende</span>
            </b-button>
          </b-col>
          <b-col
              class="d-flex align-items-center justify-content-end"
              cols="12"
              md="6"
          >
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="$refs.pdfViewer.print()"
            >
              <span>Printen</span>
            </b-button>
          </b-col>

        </b-row>
      </div>
    </b-card>
    <pdf
        v-if="url"
        ref="pdfViewer"
        :src="url"
        :page="page"
        @num-pages="pageCount = $event"
        @page-loaded="showOverlay = false"
        @error="error"
    ></pdf>
  </b-overlay>

</template>

<script>
import {
  BButton, BCard,
  BCol,
  BOverlay, BRow,
} from 'bootstrap-vue'
import pdf from 'vue-pdf'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCard,
    pdf,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      url: null,
      showOverlay: true,
      page: 1,
      pageCount: 0,
    }
  },
  mounted() {
    this.url = this.$store.getters['app/getPdfUrl']
  },
  destroyed() {
    this.url = null
  },
  methods: {
    error() {
      this.showOverlay = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
